@media(max-width:767px) {
    .card {
        margin: 1rem 0.7rem 1rem;
        max-width: 80vw;
    }
}

.card_custom {
    border-radius: 1rem !important;
    box-shadow: 10px -0px 0px #d20300 !important;
}

.slider_image {
    width: 6.2rem;
    border-radius: 5rem;
    margin: 1.3rem auto 1rem auto;
}

.col_md_4 {
    padding: 0 0.5rem;
}

.card_title {
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: bold;
    font-family: 'IM Fell French Canon SC';
}

.card_text {
    text-align: center;
    padding: 1rem 2rem;
    font-size: 0.8rem;
    color: rgb(82, 81, 81);
    line-height: 1.4rem;
}

.footer {
    border-top: none;
    text-align: center;
    line-height: 1.2rem;
    padding: 2rem 0 1.4rem 0;
    font-family: 'Varela Round';
}

#name {
    font-size: 0.8rem;
    font-weight: bold;
}

#position {
    font-size: 0.7rem;
}

.link_clr {
    color: rgb(151, 248, 6);
    font-weight: bold;
}

.link_clr:hover {
    color: rgb(151, 248, 6);
}