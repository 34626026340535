body {
    background: aliceblue;
}

.gtco_testimonials {
    position: relative;
    margin-top: 30px;
}

.gtco_testimonials h2 {
    font-size: 30px;
    text-align: center;
    color: #333333;
    margin-bottom: 50px;
}

.gtco_testimonials .owl-stage-outer {
    padding: 30px 0;
}

.gtco_testimonials .owl-nav {
    display: none;
}

.gtco_testimonials .owl-dots {
    text-align: center;
}

.gtco_testimonials .owl-dots span {
    position: relative;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: block;
    background: #fff;
    border: 2px solid #01b0f8;
    margin: 0 5px;
}

.gtco_testimonials .owl-dots .active {
    box-shadow: none;
}

.gtco_testimonials .owl-dots .active span {
    background: #01b0f8;
    box-shadow: none;
    height: 12px;
    width: 12px;
    margin-bottom: -1px;
}

.gtco_testimonials .card {
    background: #fff;
    box-shadow: 0 8px 30px -7px #c9dff0;
    margin: 0 20px;
    padding: 0 10px;
    border-radius: 20px;
    border: 0;
}

.gtco_testimonials .card .card_img_top {
    max-width: 100px;
    border-radius: 50%;
    margin: 15px auto 0;
    box-shadow: 0 8px 20px -4px #95abbb;
    width: 100px;
    height: 100px;
}

.gtco_testimonials .card h5 {
    color: #01b0f8;
    font-size: 21px;
    line-height: 1.3;
}

.gtco_testimonials .card h5 span {
    font-size: 18px;
    color: #666666;
}

.gtco_testimonials .card p {
    font-size: 18px;
    color: #555;
    padding-bottom: 15px;
}

.gtco_testimonials .active {
    opacity: 0.5;
    transition: all 0.3s;
}

.gtco_testimonials .center {
    opacity: 1;
}

.gtco_testimonials .center h5 {
    font-size: 24px;
}

.gtco_testimonials .center h5 span {
    font-size: 20px;
}

.gtco_testimonials .center .card_img_top {
    max-width: 100%;
    height: 120px;
    width: 120px;
}

@media (max-width: 767px) {
    .gtco_testimonials {
        margin-top: 20px;
    }
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    outline: 0;
}

.owl-carousel button.owl-dot {
    outline: 0;
}