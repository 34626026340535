@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap');
body {
    font-family: 'Lato', sans-serif !important;
}


/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.nav-links>ul>li,
.nav-links>ul>.contact-btn-blck {
    list-style: none;
}

.logo>img {
    max-width: 65px;
}

header {
    position: sticky;
    top: 0px;
    z-index: 9999;
    float: none;
    background-color: #ebf0f1;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.59);
}

.nav-links>ul>li>a {
    color: #001b69 !important;
    text-decoration: none;
}

h1.main-heading {
    font-size: 38px !important;
}

h1.main-heading,
p.sub-heading {
    color: #001b69 !important;
}

p.sub-heading {
    font-size: 0.8rem;
}

.contact-btn-blck>li:nth-child(1) a {}

.contact-btn-blck>li:nth-child(2) a {
    border-radius: 8px;
    font-size: 20px;
    color: #fff;
    background-color: #d20300;
}

.resource-btn {
    border: none !important;
}

.resource-btn:hover,
.resource-btn {
    background: #ebf0f1;
    color: #001b69 !important;
}

.resource-btn::after {
    display: none;
}

.dropdown-menu {
    border-radius: 10px !important;
    background: #fff;
    border: none;
}

.btn {
    vertical-align: middle !important;
}

.rqst-btn-blck>button {
    letter-spacing: 2px;
    background: #d20300 !important;
    border: none;
}

.rqst-btn-blck>button:hover {
    background: #32343a !important;
} */

.banner {
    height: 550px;
    background: url('../public/assets/banner_bg.png') no-repeat left center /cover;
    overflow: hidden;
    padding-top: 2%;
}

.service_block {
    background: url('../public/assets/service_bg.jpg') no-repeat center center /cover;
    padding-bottom: 5%;
}

.service_block .container-8.w-container {
    max-width: 93% !important;
}


/* .service_block .container-8 {
    padding-top: 4% !important;
} */

.service_block img {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.service_block .section {
    background-color: transparent !important;
}

.container-6 {
    background-color: transparent !important;
}

.button {
    appearance: none;
    background-color: #d20300;
    border-width: 0;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: Clarkson, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1em;
    margin: 0;
    opacity: 1;
    outline: 0;
    padding: 1.2em 1.9em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-rendering: geometricprecision;
    text-transform: uppercase;
    transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1), background-color 100ms cubic-bezier(.694, 0, 0.335, 1), color 100ms cubic-bezier(.694, 0, 0.335, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
    letter-spacing: 1px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border-radius: 30px;
}

.button {
    box-shadow: 1px 1px 10px rgba(210, 3, 0, 0.5) !important;
    color: #fff !important;
}

.button:before {
    animation: opacityFallbackOut .5s step-end forwards;
    backface-visibility: hidden;
    background-color: #EBEBEB;
    clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
    width: 100%;
}


/* .button:hover:before {
    animation: opacityFallbackIn 0s step-start forwards;
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
} */

.button:after {
    background-color: #FFFFFF;
}

.button span {
    z-index: 1;
    position: relative;
}

button.service_block_button {
    background: #d20300 !important;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    top: 40px;
}

.service_block a,
.banner a {
    color: #fff !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
}

button.service_block_button {
    /* transition: 0.2s all linear; */
}

button.service_block_button:hover a {
    color: #fff !important;
}

button.service_block_button:hover {
    box-shadow: 1px 1px 10px rgba(210, 3, 0, 0.5);
}

.feature_block {
    background: url('../public/assets/feature_bg3.jpg') no-repeat center center /cover;
}

body {
    background: #fff !important;
}

.feature_cards {
    margin-top: 5%;
}

.feature_cards img {
    transition: 0.5s all ease-in-out;
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%, 0 99%);
}

.feature_cards img:hover {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 85%, 0 85%);
}

.small-flag {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    /* background-image: url('../public/assets/uk.svg'); */
    text-align: center;
    margin: 0 auto;
    border: 5px solid #fff;
    box-shadow: 0 0 10px 0 rgba(18, 29, 39, .16);
    background-size: cover;
    background-repeat: no-repeat;
}

.country-flag {
    margin-top: -72px;
    z-index: 1;
}

.card-image {
    transition: 0.5s all ease-in-out;
    height: 20rem;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%, 0 99%);
}

.card-image,
.feature-card {
    border-radius: 5px !important;
}

.spacer-block {
    padding-top: 74px;
    background: #fff;
}

.feature-card {
    border: none !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.5s all ease-in-out;
}

.feature-card:hover {
    transform: translateY(-12px);
}

.feature-card:hover .card-image {
    clip-path: polygon(0 100%, 0 0, 100% 0, 100% 85%, 0 85%);
}

.country-desc {
    line-height: 25px !important;
}

.center-flex-items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner_block {
    background: url('../public/assets/partner_bg.png') no-repeat center center /cover;
    padding-bottom: 3%;
}

.contact-us-block {
    background: url('../public/assets/partner_bg.png') no-repeat center center /cover;
}

.contact-us-block input[type=text],
.contact-us-block input[type=email],
.contact-us-block textarea {
    background: #d9dee0;
    border-radius: 10px;
}

.contact-us-block textarea:focus,
.contact-us-block textarea:active {
    background: #d9dee0;
}

.about-us-block {
    background: url('../public/assets/feature_bg3.jpg') no-repeat bottom center /cover;
    padding: 2% 2%;
}

.common-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.immigration-form h5.form-heading,
.content-container h4,
.content-container h5 {
    border-left: 4px solid red;
}

.immigration-form input,
.immigration-form select,
.immigration-form textarea {
    width: 90% !important;
    height: 45px;
    border: 1px solid #ebeef2 !important;
    background: transparent;
    padding-left: 10px;
}

.immigration-form select>option {
    background: transparent !important;
}

.immigration-form textarea {
    height: auto;
}

.immigration-form .form-button,
.button-block button {
    background: #d20300 !important;
    padding: 15px !important;
    border-radius: 2px;
    border-radius: 30px;
}

.immigration-form .form-button a,
.button-block button {
    color: #fff !important;
}

button:hover .button-text {
    color: #d20300 !important;
}

.benifit-box i,
.div-1 i,
.div-2 i,
.div-3 i,
.div-4 i {
    color: #d20300 !important;
}

.country-list-block {
    padding-left: 0 !important;
}

.country-list-block li {
    list-style: none;
    box-shadow: 0 0 6px 0px rgba(29, 33, 67, 0.1);
    width: 100%;
    margin: 0 4px 10px;
}

.country-list-block li .country-links {
    display: block;
    padding: 20px 25px 20px 25px;
    position: relative;
    z-index: 1;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    font-weight: 700;
}

.country-list-block li .country-links {
    color: #001b69;
}

.country-list-block li {
    position: relative;
    transition: all 0.2s linear;
}

.country-list-block li::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.country-list-block li:hover .country-links {
    color: #fff !important;
    background: #d20300 !important;
}

.country-list-block li:hover .country-links::after {
    border-left: 7px solid #fff;
}

.country-list-block .country-links::after {
    content: '';
    width: 0;
    height: 0;
    right: 20px;
    position: absolute;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #001b69;
    box-shadow: 0 0 6px 0px rgba(29, 33, 67, 0.1);
}

.active-country-list {
    color: #fff !important;
    background: #d20300 !important;
}

.after-effect::after {
    border-left: 7px solid #fff !important;
}

.cta-block {
    max-width: 1250px;
    margin: auto;
}

.banner img {
    max-width: 100%;
    filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.4));
}

.main-heading-sub-text::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 3.5px;
    width: 35px;
    background: #d20300;
}

.banner-image img {
    animation: MoveUpDown 4.5s linear infinite;
    -webkit-animation: MoveUpDown 4.5s linear infinite;
    position: relative;
}

.about-us-img img {
    animation: MoveLeftRight 2.5s linear infinite;
    -webkit-animation: MoveLeftRight 2.5s linear infinite;
    position: relative;
    filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2)) !important;
}

.company-founder {
    padding-left: 30px;
}

.company-founder::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 2.5px;
    width: 20px;
    background: #000;
}

@keyframes MoveLeftRight {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px)
    }
    50% {
        -webkit-transform: translateX(10px);
        transform: translateX(10px)
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px)
    }
}

@keyframes MoveUpDown {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px)
    }
    50% {
        -webkit-transform: translateY(25px);
        transform: translateY(25px)
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px)
    }
}


/* @media (min-width: 1400px) {
    .banner {
        height: 720px;
    }
} */

@media (min-width: 1400px) {
    .banner {
        height: calc(100vh - 23vh);
        max-height: 720px;
    }
}

.footer-01:after {
    position: absolute;
    top: -300px;
    left: -100px;
    width: 800px;
    height: 800px;
    content: '';
    background: url('../public/assets/blob-shape.svg');
    background-position: center center;
    background-size: cover;
    z-index: -1;
    opacity: .05;
}